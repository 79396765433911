<template>
  <div class="positionT0L0" id="study_detail">
    <!-- 顶部 -->
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="top">
        <span>
          <span>微信昵称：</span>
          <a-input v-model="filter.wxName" style="width: 160px"  placeholder="请输入微信昵称" />
        </span>

        <span>
          <span>是否关联APP账号：</span>
          <a-select v-model="filter.hasRef" style="width: 80px" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body">
            <a-select-option v-for="item in appAccount" :key="item.key">{{ item.value }}</a-select-option>
          </a-select>
        </span>

        <span>
          <span>APP手机号：</span>
          <a-input v-model="filter.phone" style="width: 160px"  placeholder="请输入APP手机号" />
        </span>

        <span style="position: relative;">
          <span>企微内标签：</span>
          <a-input :value="chooseTagsName.join(',')" style="width: 160px" disabled  placeholder="请选择企微内标签" />
          <a-input class="tag-input" @click="modalVisible = true" />
        </span>
        <div class="top_rigiht">
          <a-button
            icon="search"
            type="primary"
            style="margin-right: 10px;"
            @click="fetchData"
            :disabled="isDisableSearch"
          >
            搜索
          </a-button>
          <a-button
            icon="reload"
            type="primary"
            style="margin-right: 10px;"
            @click="searchClick"
            :disabled="isDisableSearch"
          >
            重置
          </a-button>
          <a-button
            icon="reload"
            type="primary"
            :disabled="isDisableSearch"
            @click="exportClick"
          >
            导出
          </a-button>
        </div>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        :row-key="record => record.wxName"
      >
        <span slot="tags" slot-scope="tags, record">
          <a-tag v-for="(item, index) in tags" :key="index" style="margin-bottom: 5px;">{{ item }}</a-tag>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <router-link v-show="record.phone" :to="{path:'/userManage/playDataList', query: {phone: record.phone}}">
            用户播放数据
          </router-link>
        </span>
      </a-table>
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="pageInfo.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
    </a-spin>
    
    <!-- 返回顶部 -->
    <a-back-top :target="targetFn" :visibilityHeight="100" />

    <a-modal
      title="选择标签"
      :mask-closable="false"
      :visible="modalVisible"
      width="50%"
      @ok="handleSelectTag"
      @cancel="handleCancel"
    >
      <div><a @click="clearTags">清空</a></div>
      <div style="height: 500px; overflow: auto;">
        <div v-for="item in tagsList" :key="item.groupId" style="margin-bottom: 10px;">
          <b>{{ item.groupName }}</b>
          <br>
          <a-tag v-for="el in item.tags" :key="el.tagId" :color="el.checked ? '#2db7f5' : ''" style="margin-bottom: 5px;" @click="tagClick(el, item)">{{ el.tagName }}</a-tag>
        </div>
      </div>
    </a-modal>

    <!--    导出弹框    -->
    <a-modal v-model="exportModal" title="导出">
      <template #footer>
        <div style="display: flex;justify-content: flex-end">
          <a-button @click="clickExportBtn(false)" class="margin_right20">取消</a-button>
          <download-excel
            :data="tableData"
            :fields="tableDataName"
            name="企微用户管理列表.xls"
          >
            <a-button type="primary" @click="clickExportBtn(true)">确定</a-button>
          </download-excel>
        </div>
      </template>
      <p>
        <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
        <span style="margin-left: 10px">确定要导出此数据吗？</span>
      </p>
    </a-modal>
  </div>
</template>

<script>
import { getQwUsers, getQwTags } from "@/request/api/crm";
import MyPagination from "@/components/pagination/MyPagination";
import { cloneDeep } from 'lodash'
export default {
  components: { MyPagination },
  created() {
    this.getTags()
    this.fetchData();
  },
  data() {
    return {
      spinning: false, // 全局loading
      tip: "",
      exportModal: false,
      modalVisible: false,
      count: 0,
      pageInfo: {
        pageNo: 1,
        pageSize: 20,
      },
      filter: {
        wxName: '',
        hasRef: -1,
        phone: '',
        tagIds: []
      },
      isDisableSearch: false,
      tableLoading: true,
      columns: [
        {
          title: "微信昵称",
          dataIndex: "wxName",
          key: "wxName"
        },
        {
          title: "关联APP账号",
          customRender: (text, record) => {
            return record.phone ? "是" : "否";
          },
        },
        {
          title: "APP手机号",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "企微内标签",
          dataIndex: "tags",
          key: "tags",
          scopedSlots: { customRender: "tags" },
          width: '60%'
        },
        {
          title: "操作",
          width: "150px",
          dataIndex: "operation",
          key: "operation",
          align: "right",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      appAccount: [{ key: -1, value: "全部" }, { key: 1, value: "是" }, { key: 0, value: "否" }],
      tagsList: [],
      chooseTagsIds: [],
      chooseTagsName: [],
      tableDataName: {
        "微信昵称": "wxName",
        "APP手机号": "phone",
        "是否关联APP账号": {
          field: "phone",
          callback: value => {
            return +value === 0 ? '否' : '是'
          }
        },
        "标签": {
            field: "tags",
            callback: value => {
              return value.toString()
            }
        },
      },
      latestSelect: {
        tagsList: [],
        chooseTagsIds: [],
        chooseTagsName: []
      }
    };
  },
  methods: {
    async exportClick() {
      this.tip = "导出中";
      this.spinning = true;
      this.tableLoading = true;
      const params = {
        ...this.filter,
        pageSize: 0,
        tagIds: this.chooseTagsIds
      }
      const { code, data } = await getQwUsers(params)
      this.tableLoading = false;
      this.spinning = true;
      if (code !== 200) return
      this.tableData = data.list;
      if (data.list.length > 0) {
        this.exportModal = true
      } else {
        this.$message.warning('暂无导出数据')
      }
    },
    // 导出
    clickExportBtn(flag) {
      this.exportModal = false
      this.spinning = false
      if (flag) this.$message.success('导出成功')
    },
    // 清空
    clearTags() {
      this.initTagChecked()
      this.tagsList = this.latestSelect.tagsList
      this.chooseTagsIds = this.latestSelect.chooseTagsIds
      this.chooseTagsName = this.latestSelect.chooseTagsName

      // this.$forceUpdate()
    },
    handleCancel() {
      this.tagsList = this.latestSelect.tagsList
      this.chooseTagsIds = this.latestSelect.chooseTagsIds
      this.chooseTagsName = this.latestSelect.chooseTagsName
      this.modalVisible = false
      // this.$forceUpdate()
    },
    // 选择标签
    handleSelectTag() {
      this.tagsList.forEach(el => {
        el.tags.forEach(element => {
          if (element.checked) {
            if (!this.chooseTagsIds.includes(element.tagId)) { 
              this.chooseTagsIds.push(element.tagId)
              this.chooseTagsName.push(element.tagName)
            }
          } else {
            const index = this.chooseTagsIds.indexOf(element.tagId)
            if (index !== -1) {
              this.chooseTagsIds.splice(index, 1)
              this.chooseTagsName.splice(index, 1)
            }
          }
        })
      })
      this.latestSelect.tagsList = cloneDeep(this.tagsList)
      this.latestSelect.chooseTagsIds = this.chooseTagsIds
      this.latestSelect.chooseTagsName = this.chooseTagsName
      this.modalVisible = false
    },
    initTagChecked() {
      this.tagsList.forEach(el => {
        el.tags.forEach(element => {
          element.checked = false
        })
      })
    },
    tagClick(el, item) {
      let tag = this.tagsList.filter(el => el.groupId === item.groupId)[0]
      // 多选
      if (item.groupName === '所购买付费课程') {
        el.checked = !el.checked
      } else {
        // 单选
        el.checked = !el.checked
        tag.tags.forEach(element => {
          if (element.tagId !== el.tagId) {
            element.checked = false
          }
        });
      }
      this.$set(this.tagsList, this.tagsList.indexOf(tag), tag)
    },
    async getTags() {
      const { code, data } = await getQwTags()
      if (code !== 200) return
      this.tagsList = data
      this.latestSelect.tagsList = cloneDeep(data)
    },
    // 搜索的按钮
    searchClick() {
      this.filter = this.$options.data().filter
      this.pageInfo = this.$options.data().pageInfo
      this.chooseTagsIds =[]
      this.chooseTagsName = []
      this.initTagChecked()
      this.fetchData();
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageInfo.pageNo = current;
      this.pageInfo.pageSize = pageSize;
      this.fetchData();
    },
    // 获取课节列表
    fetchData() {
      this.tableLoading = true;
      this.isDisableSearch = true;
      const params = {
        ...this.filter,
        ...this.pageInfo,
        tagIds: this.chooseTagsIds
      }
      getQwUsers(params).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          this.isDisableSearch = false;
          this.tableData = data.list;
          this.count = data.count;
        }
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#study_detail");
    },
  },
};
</script>
 
<style lang="less" scoped>
.ant-input-disabled {
  cursor: pointer;
  background-color: #f0f0f0; /* 改变背景颜色 */
  color: #333; /* 改变文字颜色 */
  border-color: #ccc; /* 改变边框颜色 */
}
#study_detail {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.title {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 16px;
  background-color: #ecf8fe;
  p {
    margin-left: 20px;
    line-height: 30px;
    font-size: 14px;
    margin-bottom: 0 !important;
    // &:nth-child(2) {
    //   color: #ccc;
    // }
  }
}
.top {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .top_left {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }
  .top_rigiht {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
      /deep/.ant-input {
        width: 200px;
      }
    }
  }
}
.tag-input {
  position: absolute;
  right: 0;
  width: 160px;
  opacity: 0;
  cursor: pointer;
}
</style>